import React from "react"

import PartnerList from "./partnerList"

import "./sectorBreakdown.scss"

const SectorBreakdown = ({ sectors, partners }) => {
  return (
    <div className="sector-breakdown">
      {sectors.map(s => (
        <PartnerList
          key={s.key}
          sector={s}
          partners={partners.filter(p => p.sector && p.sector.key === s.key)}
        />
      ))}
    </div>
  )
}

export default SectorBreakdown
