import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/common/contact"
import Relations from "../components/common/relations"
import Highlight from "../components/portfolio/highlight"
import Content from "../components/portfolio/content"

const PortfolioPage = ({ location }) => {
  const { pages } = useStaticQuery(graphql`
    query {
      pages {
        portfolio {
          title
          sections {
            title
            key
            blocks {
              title
              content
              order
            }
          }
        }
      }
    }
  `)
  const { title, sections } = pages.portfolio
  return (
    <Layout>
      <SEO title={title} />
      <Highlight
        title={title}
        section={sections.find(s => s.key === "highlight")}
      />
      <Content
        section={sections.find(s => s.key === "portfolio-content")}
        location={location}
      />
      <Contact
        section={sections.find(s => s.key === "contact")}
        hidePicture={true}
      />
      <Relations section={sections.find(s => s.key === "relations")} />
    </Layout>
  )
}

export default PortfolioPage
