import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import VizSensor from "react-visibility-sensor"

import Counters from "../common/counters"
import CountryMap from "../common/countryMap"
import SectorBreakdown from "./sectorBreakdown"

import { useWindowWidth } from "../../services/hooks"

import "./content.scss"
import variables from "../variables.scss"

const Content = ({ section, location }) => {
  const [visibleBlock, setVisibleBlock] = useState([true, false])
  const width = useWindowWidth()
  const isMobile = width < parseInt(variables.mobile)
  const { pages } = useStaticQuery(graphql`
    query {
      pages {
        portfolio {
          components {
            counters {
              title
              value
              unit
            }
            partners {
              name
              description
              regions {
                key
              }
              lat
              long
              sector {
                key
              }
            }
            sectors {
              title
              key
            }
          }
        }
      }
    }
  `)
  const { counters, partners, sectors } = pages.portfolio.components
  const goTo = index => {
    document.getElementById(`portfolio-block-${index + 1}`).scrollIntoView()
  }

  useEffect(() => {
    if (location.hash && location.hash.includes(2)) {
      setVisibleBlock([false, true])
    }
  }, [location.hash])

  useEffect(() => {
    window.onscroll = () => {
      if (!window.scrollY) {
        setVisibleBlock([true, false])
      }
    }
  }, [])

  return (
    <section>
      <div style={{ overflow: "unset" }}>
        <div className="floating-navigation">
          <div className="floating-navigation-box">
            {section.blocks.map((b, i) => (
              <h3
                key={i}
                className={visibleBlock[i] ? "active" : ""}
                onClick={() => goTo(i)}
              >
                {b.title}
              </h3>
            ))}
          </div>
        </div>
        <div className="portfolio-content">
          {section.blocks
            .sort((a, b) => a.order - b.order)
            .map((b, i) => (
              <VizSensor
                offset={{ top: 100 }}
                onChange={isVisible => {
                  if (!isVisible) {
                    setVisibleBlock(visibleBlock =>
                      visibleBlock.map((_, index) =>
                        i === index ? isVisible : !isVisible
                      )
                    )
                  }
                }}
                partialVisibility
                key={i}
              >
                <div
                  className="portfolio-block"
                  id={`portfolio-block-${i + 1}`}
                >
                  <div className="content">
                    {isMobile && <h3>{b.title}</h3>}
                    <p>{b.content}</p>
                    {!i ? (
                      <>
                        <div className="map-container">
                          <CountryMap partners={partners} />
                        </div>
                        <div className="counter-container">
                          <Counters counters={counters} />
                        </div>
                      </>
                    ) : (
                      <SectorBreakdown sectors={sectors} partners={partners} />
                    )}
                  </div>
                </div>
              </VizSensor>
            ))}
        </div>
      </div>
    </section>
  )
}

export default Content
