import React, { useContext } from "react"
import VizSensor from "react-visibility-sensor"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"

import { ScrollContext } from "../layout"

import pdf from "../../files/VillageMedia_Prezentacio.pdf"

import "./highlight.scss"

const Highlight = ({ title, section }) => {
  const [, setFirstBlockVisibility] = useContext(ScrollContext)
  return (
    <VizSensor
      onChange={isVisible => setFirstBlockVisibility(isVisible)}
      partialVisibility
    >
      <section id="portfolio-highlight">
        <div>
          <h1>{title}</h1>
          <div>
            {section.blocks.map((b, i) => (
              <div key={i} className="portfolio-highlight-text">
                <ReactMarkdown
                  children={b.content}
                  remarkPlugins={[remarkGfm]}
                />
              </div>
            ))}

            <span>
              <a
                href={pdf}
                target="_blank"
                style={{
                  textTransform: "uppercase",
                  fontFamily: "Montserrat, sans-serif",
                  fontSize: "14px",
                  fontWeight: 700,
                  marginTop: "10px",
                  display: "block",
                  lineHeight: 1.5,
                }}
              >
                Bemutatkozó prezentáció letöltése&nbsp;{">"}
              </a>
            </span>
          </div>
        </div>
      </section>
    </VizSensor>
  )
}

export default Highlight